import dayjs from 'dayjs';

import {
  ADDITIONAL_PET_RATE_CONFIG,
  EASTER_SURCHARGE_DATES,
  EXT_STAY_DISCOUNT_CONFIG,
  PUPPY_SURCHARGE_CONFIG,
  SEASONAL_SURCHARGE_RATE_CONFIG,
} from './constants';

import type { RateDetail, RateDetailsConfig, ServiceRateDetails } from './types';
import type { SitterProfileService } from '~/common/types/sitter';

export const getSurchargeDates = (todayString: string): string => {
  const today = dayjs(todayString);
  const currentYear = today.year().toString();
  const nextYear = today.add(1, 'year').year().toString();
  const lastYear = today.subtract(1, 'year').year().toString();

  const currentEasterDates = EASTER_SURCHARGE_DATES[currentYear];
  const nextEasterDates = EASTER_SURCHARGE_DATES[nextYear];

  const currentEasterEnd = dayjs(`${currentEasterDates?.end}-${currentYear}`);
  const currentEasterStart = dayjs(`${currentEasterDates?.start}-${currentYear}`);

  const nextEasterEnd = dayjs(`${nextEasterDates?.end}-${nextYear}`);
  const nextEasterStart = dayjs(`${nextEasterDates?.start}-${nextYear}`);

  if (!currentEasterDates || !nextEasterDates) {
    return `8th Dec ${currentYear} - 3rd Jan ${nextYear}`;
  }

  const isEasterInPast = today.isAfter(currentEasterEnd);

  if (isEasterInPast) {
    return `8th Dec ${currentYear} - 3rd Jan ${nextYear}, and ${nextEasterStart.format(
      'DD MMM YYYY'
    )} - ${nextEasterEnd.format('DD MMM YYYY')}`;
  }

  return `8th Dec ${lastYear} - 3rd Jan ${currentYear}, and ${currentEasterStart.format(
    'DD MMM YYYY'
  )} - ${currentEasterEnd.format('DD MMM YYYY')}`;
};

// NOTE: exported for unit testing purposes only
export const getAdditionalRate = (
  serviceTypeId: number,
  rateValue: number | null,
  config: RateDetailsConfig,
  descriptionModifier?: number | null
): RateDetail | null => {
  if (!rateValue) {
    return null;
  }

  let newDescription;
  if (config.description && typeof config.description === 'function' && descriptionModifier) {
    newDescription = config.description(descriptionModifier);
  } else if (typeof config.description === 'object') {
    newDescription = config.description[serviceTypeId];
  } else if (typeof config.description === 'string') {
    newDescription = config.description;
  }

  return {
    description: newDescription,
    rateValue: `${config.rateValuePrefix || ''}${rateValue}${config.rateValuePostfix || ''}`,
    title: config.title,
    toolTip: config.toolTip,
  };
};

export const getSeasonalToolTip = (): string =>
  `This surcharge will be added for any days that fall during peak periods.\n\nOnly applies to the first pet in booking.\n\nDates that Mad Paws counts as peak period are: ${getSurchargeDates(
    dayjs().format('YYYY-MM-DD')
  )}`;

export const getServicesWithAdditionalRates = (
  sitterProfileServices: SitterProfileService[]
): ServiceRateDetails[] =>
  sitterProfileServices.map((serviceToSaturate) => {
    const {
      basePrice,
      timeUnit,
      serviceTypeId,
      additionalPetRate,
      extStayDiscount,
      seasonalSurcharge,
      puppySurcharge,
      extStayTime,
    } = serviceToSaturate;

    return {
      service: {
        basePrice,
        timeUnit,
        serviceTypeId,
      },
      additionalPetRate: getAdditionalRate(
        serviceTypeId,
        additionalPetRate,
        ADDITIONAL_PET_RATE_CONFIG
      ),
      seasonalSurcharge: getAdditionalRate(serviceTypeId, seasonalSurcharge, {
        ...SEASONAL_SURCHARGE_RATE_CONFIG,
        toolTip: getSeasonalToolTip(),
      }),
      extStayDiscount: getAdditionalRate(
        serviceTypeId,
        extStayDiscount,
        EXT_STAY_DISCOUNT_CONFIG,
        extStayTime
      ),
      puppySurcharge: getAdditionalRate(serviceTypeId, puppySurcharge, PUPPY_SURCHARGE_CONFIG),
    };
  });
