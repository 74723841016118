import { AnimatedSpinner, Button, Heading } from '@madpaws/design-system';
import React from 'react';

import { getServiceTypeIdFromSearchFilters } from '~/common/utils/search';

import { BasicSitterCard } from './BasicSitterCard/BasicSitterCard';
import styles from './SitterProfileDesktopStickyHeader.module.css';
import { FavoriteButton } from '../components/FavoriteButton/FavoriteButton';
import { hasLessOrEqualCharactersThan } from '../utils';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';
import type { SitterProfileStickyHeader } from '~/common/types/sitter';

type SitterProfileDesktopStickyHeaderProps = {
  ctaId: string;
  isContactButtonLoading: boolean;
  isSitterLiked: boolean;
  searchFilters: SearchFilters;
  setIsContactButtonLoading: (isContactButtonLoading: boolean) => void;
  setIsSitterLiked: (isSitterLiked: boolean) => void;
  stickyHeader: SitterProfileStickyHeader;
};

export const SitterProfileDesktopStickyHeader = ({
  ctaId,
  isContactButtonLoading,
  isSitterLiked,
  searchFilters,
  setIsContactButtonLoading,
  setIsSitterLiked,
  stickyHeader,
}: SitterProfileDesktopStickyHeaderProps): ReactElement => {
  const { baseRates, firstName, sitterId } = stickyHeader;
  const { service: serviceType } = searchFilters;
  const FIRST_NAME_LIMIT = 10;

  const shouldDisplayFirstName = hasLessOrEqualCharactersThan(FIRST_NAME_LIMIT, firstName);
  const serviceTypeIdFromSearchFilters = getServiceTypeIdFromSearchFilters(serviceType);
  const { basePrice, timeUnit } =
    baseRates.find(({ serviceTypeId: id }) => id === serviceTypeIdFromSearchFilters) ??
    baseRates[0];

  const handleClick = (): void => {
    // Find the contact widget's CTA button wrapper by its ID
    const contactWidgetButtonWrapper = document.getElementById(ctaId);

    if (!contactWidgetButtonWrapper) {
      return;
    }

    // Find the contact widget's CTA button we want to trigger a click event on
    const contactWidgetButton = contactWidgetButtonWrapper.querySelector('button');

    if (!contactWidgetButton) {
      return;
    }

    // Trigger click event on the contact widget's CTA button
    contactWidgetButton.click();

    // Set the contact button to loading state
    setIsContactButtonLoading(true);
  };

  return (
    <div className={styles.root}>
      <div className={styles.contentWrapper}>
        <BasicSitterCard stickyHeader={stickyHeader} />
        <div className={styles.bookingDetailsContainer}>
          <div className={styles.priceInformation}>
            <span className={styles.from}>From</span>
            <Heading size="small2x">${basePrice}</Heading>
            <span className={styles.timeUnit}>a {timeUnit}</span>
          </div>
          <div className={styles.contactSitterButton}>
            <Button
              iconLeading={isContactButtonLoading ? <AnimatedSpinner size="medium" /> : undefined}
              isDisabled={isContactButtonLoading}
              label={shouldDisplayFirstName ? `Contact ${firstName}` : `Contact sitter`}
              onClick={handleClick}
            />
          </div>
          <div className={styles.likedButton}>
            <FavoriteButton
              isSitterLiked={isSitterLiked}
              setIsSitterLiked={setIsSitterLiked}
              sitterId={sitterId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
