import { useMutation } from '@apollo/client';
import { IconButton, IconHeartFilled, IconHeartOutlined } from '@madpaws/design-system';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import { useUserLoggedInContext } from '~/components/UserAuthProvider/UserAuthProvider';
import { trackEvent } from '~/components/analytics/analytics';
import {
  CLICKED_FAVOURITE_SITTER_EVENT_NAME,
  REMOVED_FAVOURITE_SITTER_EVENT_NAME,
} from '~/components/analytics/constants';
import { getLocalStorage, removeLocalStorage } from '~/utils/localStorage';

import styles from './FavoriteButton.module.css';
import { LoginRequiredDialog } from './LoginRequiredDialog';
import { FAVORITE_SITTER_ID_LOCAL_STORAGE_KEY } from './constants';
import { UPDATE_FAVORITE_SITTER } from '../../serverSideProps/queries';
import { getDefaultEventProperties } from '../../tracking/getDefaultEventProperties';

import type { ReactElement } from 'react';

type Props = {
  isSitterLiked: boolean;
  setIsSitterLiked: (isSitterLiked: boolean) => void;
  sitterId: number;
};

export const FavoriteButton = ({
  isSitterLiked,
  sitterId,
  setIsSitterLiked,
}: Props): ReactElement => {
  const { isUserLoggedIn } = useUserLoggedInContext();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isAnimating, setIsAnimating] = React.useState(false);

  const [updateFavoriteSitter] = useMutation(UPDATE_FAVORITE_SITTER, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      const eventName = isSitterLiked
        ? REMOVED_FAVOURITE_SITTER_EVENT_NAME
        : CLICKED_FAVOURITE_SITTER_EVENT_NAME;

      trackEvent(eventName, { ...getDefaultEventProperties(sitterId) });
    },
    onError: () => {
      // Reset the state to the previous value
      setIsSitterLiked(!isSitterLiked);
    },
  });

  useEffect(() => {
    const favoriteSitterId = getLocalStorage(FAVORITE_SITTER_ID_LOCAL_STORAGE_KEY, 0);
    // In case user is logged in
    // and favoriteSitterId in local storage is the same as the current sitterId
    // but sitter is not liked, then like the sitter
    if (isUserLoggedIn && favoriteSitterId === sitterId && isSitterLiked === false) {
      setIsSitterLiked(true);
      updateFavoriteSitter({
        variables: {
          sitterId,
          isFavorite: true,
        },
      });
      removeLocalStorage(FAVORITE_SITTER_ID_LOCAL_STORAGE_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  const onClick = (): void => {
    if (!isUserLoggedIn) {
      setIsDialogOpen(true);

      return;
    }

    setIsSitterLiked(!isSitterLiked);
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 500);

    updateFavoriteSitter({
      variables: {
        sitterId,
        isFavorite: !isSitterLiked,
      },
    });
  };

  return (
    <div
      className={classNames({
        [styles.isLikedAnimation]: isAnimating && isSitterLiked,
        [styles.isLiked]: isSitterLiked,
      })}
    >
      <IconButton label="Favorite" onClick={onClick} variant="secondary">
        {isSitterLiked ? <IconHeartFilled /> : <IconHeartOutlined />}
      </IconButton>
      <LoginRequiredDialog
        isOpen={isDialogOpen}
        onDialogClose={(): void => setIsDialogOpen(false)}
        sitterId={sitterId}
      />
    </div>
  );
};
