import { useContext } from 'react';

import { AuthenticationDialogContext } from './AuthenticationProvider';

type FeatureFlagsActions = {
  openAuthDialog: () => void;
};

export const useAuthDialog = (): FeatureFlagsActions => {
  const { openAuthDialog } = useContext(AuthenticationDialogContext);

  return { openAuthDialog };
};
